import { FETCHING_UPDATE_READ_MSG } from '../constants'

const TOKEN_REQUESTED = "TOKEN_REQUESTED";
const TOKEN_RECEIVED = "TOKEN_RECEIVED";
const TOKEN_FAILED = "TOKEN_FAILED";
const CONVERSATION_REQUESTED = "CONVERSATION_REQUESTED";
const CONVERSATION_RECEIVED = "CONVERSATION_RECEIVED";
const CONVERSATION_FAILED = "CONVERSATION_FAILED";
const IMAGE_REQUESTED = "IMAGE_REQUESTED";
const IMAGE_RECEIVED = "IMAGE_RECEIVED";
const IMAGE_FAILED = "IMAGE_FAILED";

const initialState = {
  isFetching: false,
  error: false,
  success: false,
  channel: '',
  readMsg: 10,
  status: "",
  data: [],
  twilioToken: "",
  patientToken: '',
  imgCompany:''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_UPDATE_READ_MSG:
      return {
        ...state,
        readMsg: 25
      };
    
    case TOKEN_REQUESTED:
      return {
        ...state,
        status: "waiting"
      };
    
    case TOKEN_RECEIVED:
      return {
        ...state,
        status: "received",
        twilioToken: action.payload
      };
    
    case TOKEN_FAILED:
      return {
        ...state,
        status: "failed",
        error: action.payload
      };
    
    case CONVERSATION_REQUESTED:
      return {
        ...state,
        status: "waiting"
      };
    
    case CONVERSATION_RECEIVED:
      return {
        ...state,
        status: "received",
        data: action.payload
      };
    
    case CONVERSATION_FAILED:
      return {
        ...state,
        status: "failed",
        error: action.payload
      };
      case IMAGE_REQUESTED:
      return {
        ...state,
        status: "waiting"
      };
    
    case IMAGE_RECEIVED:
      return {
        ...state,
        status: "received",
        imgCompany: action.payload
      };
    
    case IMAGE_FAILED:
      return {
        ...state,
        status: "failed",
        error: action.payload
      };
    
    default:
      return state;
  }
};
