import React from "react";
import { Client as ConversationsClient } from "@twilio/conversations";
import Conversation from "./Conversation";
import { withTranslation } from 'react-i18next';
import {
  getTwilioPatientToken,
  initConversation,
  getCompanyImage
} from "../actions/index.js";
import { connect } from 'react-redux'
import Splash from "./Splash";
import BrowserError from "./BrowserError";

import firebase from 'firebase';
import 'firebase/messaging';
import { preguntarPermisos, inicializarFirebase } from "../firebaseConfig";
import {awsPutItemFCM} from '../aws/dynamo';



class ConversationsApp extends React.Component {
  constructor(props) {
    super(props);

    const name = "";
    const loggedIn = name !== "";

    this.state = {
      name,
      loggedIn,
      token: null,
      statusString: null,
      conversationsReady: false,
      conversations: [],
      selectedConversationSid: null,
      newMessage: "",
      patientToken: "",
      tokenFCM: "",
      readyFCM: false,
    };
  }

  componentDidMount = () => {

    inicializarFirebase();
    preguntarPermisos();
    this.props.getConversation(this.props.token);
    this.props.getCompanyImg(this.props.token);
    this.getTokenFirebase();
  };

  llenarToken = async (tokenFCM) => {
    this.setState({ tokenFCM: tokenFCM });
  };

  getTokenFirebase = async () => {
    const messaging = firebase.messaging();
    const response = await messaging.getToken();
    let tokenFCM = await response;
    
    messaging.onMessage((payload) => {
    });

    await this.llenarToken(tokenFCM)
  };

  
  static getDerivedStateFromProps(nextProps, prevState) {
  
    if ((prevState.name === "") && nextProps.conversation.data.length > 0) {
      return {name:`${nextProps.conversation.data[0].patientId}_patient`}
    }

  else return null;
}

  componentDidUpdate() {
    
    if (
      (this.props.conversation.twilioToken === "")
      && (this.state.name !== "")
      && (this.props.conversation.status !== "waiting")
    ) {
      this.props.getToken(this.props.token)
    }

    if ((this.props.conversation.twilioToken !== "") && (this.state.conversations.length === 0)) {
      this.initConversations();
    }

    if ((!this.state.readyFCM) && (this.state.tokenFCM !== "")  && (this.state.name !== "")) {
      awsPutItemFCM(this.state.name,this.state.tokenFCM)
      this.setState({readyFCM:true})
    }
  }

  logOut = (event) => {
    if (event) {
      event.preventDefault();
    }

    this.setState({
      name: "",
      loggedIn: false,
      token: "",
      conversationsReady: false,
      messages: [],
      newMessage: "",
      conversations: []
    });

    localStorage.removeItem("name");
    this.conversationsClient.shutdown();
  };

  initConversations = async () => {
    window.conversationsClient = ConversationsClient;
    this.conversationsClient = await ConversationsClient.create(this.props.conversation.twilioToken);
    this.setState({ statusString: "Connecting to Twilio…" });

    this.conversationsClient.on("connectionStateChanged", (state) => {
      if (state === "connecting")
        this.setState({
          status: "default"
        });
      if (state === "connected") {
        this.setState({
          status: "success"
        });
      }
      if (state === "disconnecting")
        this.setState({
          conversationsReady: false,
          status: "default"
        });
      if (state === "disconnected")
        this.setState({
          conversationsReady: false,
          status: "warning"
        });
      if (state === "denied")
        this.setState({
          conversationsReady: false,
          status: "error"
        });
    });
    this.conversationsClient.on("conversationJoined", (conversation) => {
      this.setState(
        {
          conversations: [...this.state.conversations, conversation]
        }, () => {
          window.document.title = 'Chat con ' + JSON.parse(this.state.conversations[0].channelState.friendlyName).companyName;
        }
      );
    });
    this.conversationsClient.on("conversationLeft", (thisConversation) => {
      this.setState({
        conversations: [...this.state.conversations.filter((it) => it !== thisConversation)]
      });
    });
  };



  render() {
    
    const { conversations } = this.state;
    const selectedConversation = conversations[0];

    if (this.state.browserError) return <BrowserError />

    if (selectedConversation) {
      return (
        <Conversation
          conversationProxy={selectedConversation}
          myIdentity={this.state.name}
        />
      );
    }

    return <Splash/>

  }
}

const mapStateToProps = (state) => {
  return { conversation: state.conversation };
}

function mapDispatchToProps(dispatch) {
  return {
    getToken: (token) => dispatch(getTwilioPatientToken(token)),
    getConversation: (token) => dispatch(initConversation(token)),
    getCompanyImg: (token) => dispatch(getCompanyImage(token)),
  };
}

export default withTranslation()(connect(
  mapStateToProps,
  mapDispatchToProps
)(ConversationsApp));
