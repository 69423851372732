import './assets/css/style.css';
import ConversationsApp from "./screens/ConversationsApp";
import './App.css';
import ReactGA from "react-ga";
import React, { useEffect } from "react";
import 'firebase/messaging';


const App = ({ match }) => {

  useEffect(() => {    
    ReactGA.initialize('UA-80428000-15');
  }, [])

  
  return (
    <ConversationsApp token={match.params.token}/>
  ) 
}

export default App;
