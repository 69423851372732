var ENV_ARRAY = {
  local: {
    REST_BASE_URL: "https://saludtools.dev.carecloud.com.co/fluidwise",
    DYNAMO_ACCESS_KEY_ID: "AKIA2XO4MJJTXFAQYHD4",
    DYNAMO_SECRET_ACCESS_KEY: "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",
    TWILIO_CONVERSATIONS_TABLE: "twilio_conversations_preprod",
    FCM_TOKENS_TABLE: "fcm_tokens_preprod",
    GATEWAY_INVOKE_URL: "https://7r76cr6nx9.execute-api.us-east-1.amazonaws.com/local/webhook",
  },
  dev: {
    REST_BASE_URL: "https://saludtools.dev.carecloud.com.co/fluidwise",
    DYNAMO_ACCESS_KEY_ID: "AKIA2XO4MJJTXFAQYHD4",
    DYNAMO_SECRET_ACCESS_KEY: "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",
    TWILIO_CONVERSATIONS_TABLE: "twilio_conversations_preprod",
    FCM_TOKENS_TABLE: "fcm_tokens_preprod",
    GATEWAY_INVOKE_URL: "https://7r76cr6nx9.execute-api.us-east-1.amazonaws.com/preprodpatient/webhook",
  },
  qa: {
    REST_BASE_URL: "https://saludtools.qa.carecloud.com.co/fluidwise",
    DYNAMO_ACCESS_KEY_ID: "AKIA2XO4MJJTQ6PAAENY",
    DYNAMO_SECRET_ACCESS_KEY: "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",
    TWILIO_CONVERSATIONS_TABLE: "twilio_conversations_qa",
    FCM_TOKENS_TABLE: "fcm_tokens_qa",
    GATEWAY_INVOKE_URL: "https://7r76cr6nx9.execute-api.us-east-1.amazonaws.com/qapatient/webhook",
  },
  prod: {
    REST_BASE_URL: "https://saludtools.prod.carecloud.com.co/fluidwise",
    DYNAMO_ACCESS_KEY_ID: "AKIA2XO4MJJTV3GFLDAZ",
    DYNAMO_SECRET_ACCESS_KEY: "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",
    TWILIO_CONVERSATIONS_TABLE: "twilio_conversations_prod",
    FCM_TOKENS_TABLE: "fcm_tokens_prod",
    GATEWAY_INVOKE_URL: "https://7r76cr6nx9.execute-api.us-east-1.amazonaws.com/prodpatient/webhook",
  },
};

let ENV_INFO = ENV_ARRAY.dev;
const hostname = window && window.location && window.location.hostname;

if(hostname === 'localhost') {
  ENV_INFO = ENV_ARRAY.qa;
} else if(hostname === 'chat.dev.saludtools.com') {
  ENV_INFO = ENV_ARRAY.dev;
} else if(hostname === 'chat.qa.saludtools.com') {
  ENV_INFO = ENV_ARRAY.qa;
} else if((hostname === 'chat.prod.saludtools.com') || (hostname === 'chat.saludtools.com'))  {
  ENV_INFO = ENV_ARRAY.prod;
}
else {
  ENV_INFO = ENV_ARRAY.dev;
}

export const ENV = ENV_INFO;