import { ENV } from '../api-config';
 
const AWS = require("aws-sdk");

AWS.config.update({
  region: "us-east-1",
  endpoint: "dynamodb.us-east-1.amazonaws.com",
  credentials: {
    accessKeyId: ENV.DYNAMO_ACCESS_KEY_ID,
    secretAccessKey: ENV.DYNAMO_SECRET_ACCESS_KEY
  }
});

const ddb = new AWS.DynamoDB({ apiVersion: '2012-08-10' });

export const awsGetItem = (id,user_id) => {
  var params = {
    Key: {
      "id": {
        S: id
      },
      "user_id": {
        N: user_id
      }
    },
    TableName: ENV.TWILIO_CONVERSATIONS_TABLE,
  };


    ddb.getItem(params, function (err, data) {
      if (err) console.log(err, err.stack); // an error occurred
    });
};

export const awsGetItemFCM = (user_id_type) => {
  var params = {
    Key: {
      "user_id_type": {
        S: user_id_type
      }
    },
    TableName: ENV.FCM_TOKENS_TABLE,
  };


    ddb.getItem(params, function (err, data) {
      if (err) console.log(err, err.stack); // an error occurred
    });
};

export const describeTableFCM = () => {
  var params = {

    TableName: ENV.FCM_TOKENS_TABLE,
  };


    ddb.describeTable(params, function (err, data) {
      if (err) console.log(err, err.stack); // an error occurred
    });
};




export const awsUpdateItem = (id, userId, messageId) => {
  var params = {
    Key: {
      "id": {
        S: id
      },
      "user_id": {
        N: userId
      }
    },
    TableName: ENV.TWILIO_CONVERSATIONS_TABLE,
    UpdateExpression: "set last_message_patient_sent = :r",
    ExpressionAttributeValues: {
      ":r": {
        N: messageId
      },
    },
    ReturnValues: "UPDATED_NEW"
  };


  ddb.updateItem(params, function (err, data) {
    if (err) console.log(err, err.stack); // an error occurred
  });
};




export const awsPutItemFCM = (user_id_type, fcm_token) => {
  var params = {
    Item: {
      "user_id_type": {
        S: user_id_type
      },
      "fcm_token": {
        S: fcm_token
      }
    },
    TableName: ENV.FCM_TOKENS_TABLE,
  };


    ddb.putItem(params, function(err, data) {
      if (err) {
        console.log("Error", err);
      }
    });
};


export function awsBatchItemsFCM(ids) {
  var promise = new Promise(function(resolve, reject) {
    let res = [];
  let keys = [];
  const TableName = ENV.FCM_TOKENS_TABLE;

  ids.map(p => {
      keys.push({'user_id_type': {S: p}})
  })


  var params = {
    RequestItems: {
      [TableName]: {
        Keys: keys,
        ProjectionExpression: 'fcm_token'
      }
    }
  };
    
    
    ddb.batchGetItem(params, function(err, data) {
      if (err) console.log(err, err.stack); // an error occurred
      else {
        data.Responses[TableName].map(t => {
          res.push(t.fcm_token.S)
        })
        resolve(res);
      }
    });

    });

  return promise;
}