import React from 'react';

const Attachment = ({ className, color}) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="none" width="25" height="25" viewBox="0 0 25 25">

      <path d="M6.8389 25.0002C5.08678 25.0002 3.33519 24.3854 2.00151 23.1559C-0.665867 20.697 -0.665867 16.696 2.00151 14.237L15.8818 1.44061C17.965 -0.479797 21.3548 -0.479797 23.4379 1.44061C25.5211 3.36102 25.5211 6.48602 23.4379 8.40643L11.6461 19.2765C10.1472 20.6584 7.70807 20.6584 6.20913 19.2765C4.71019 17.8947 4.71019 15.6462 6.20913 14.2643L15.2293 5.94891C15.4777 5.71991 15.8797 5.71991 16.1281 5.94891L16.4279 6.22528C16.6763 6.45428 16.6763 6.82489 16.4279 7.05389L7.40776 15.3693C6.56983 16.1418 6.56983 17.3986 7.40776 18.1716C8.24568 18.944 9.60903 18.944 10.447 18.1716L22.2388 7.30096C23.6609 5.98993 23.6609 3.85663 22.2388 2.54559C20.8161 1.23456 18.5026 1.23456 17.0804 2.54559L3.1996 15.342C1.19324 17.1916 1.19324 20.2014 3.1996 22.051C5.20596 23.9006 8.47079 23.9006 10.4771 22.051L22.01 11.4191C22.2584 11.1901 22.6604 11.1901 22.9088 11.4191L23.2086 11.6955C23.457 11.9245 23.457 12.2951 23.2086 12.5241L11.6763 23.1559C10.3426 24.3854 8.59049 25.0002 6.8389 25.0002Z" fill={color}/>
    </svg>
  )
}

export default Attachment;