import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import reducers from './reducers';
import { httpMiddleware } from './actions';
import { BrowserRouter, Route } from 'react-router-dom';
let store = createStore(reducers, {}, applyMiddleware(httpMiddleware));

ReactDOM.render(
  <Provider store={store}>
  <I18nextProvider i18n={i18n}>
      <React.StrictMode>
        <BrowserRouter>
          <Route  path="/:token" component={App} />
        </BrowserRouter>
    </React.StrictMode>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();