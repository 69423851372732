import firebase from 'firebase';
import 'firebase/messaging';

export const inicializarFirebase = () => {
  firebase.initializeApp({
    apiKey: "AIzaSyAuXOW6Wx-tYaL4CPsZqC66qwPIYHGo4Nc",
    authDomain: "saludtools-v2-app-dev.firebaseapp.com",
    databaseURL: "https://saludtools-v2-app-dev.firebaseio.com",
    projectId: "saludtools-v2-app-dev",
    storageBucket: "saludtools-v2-app-dev.appspot.com",
    messagingSenderId: "175676828417",
    appId: "1:175676828417:web:ee921abb74f1aa1fbc0900"
  });
};


export const preguntarPermisos = async () => {
  const messaging = firebase.messaging();
  let tokenFirebase = "";


    console.log(messaging.requestPermission)
    messaging.requestPermission()
      .then(function () {
        return messaging.getToken();
      })
      .then(function (token) {
        tokenFirebase = token;
      })
      .catch(function (err) {
      console.log('firebase error ocurred', err)
      })
  
  messaging.onMessage(function (payload) {
    console.log('firebase onMessage', payload)
  })
  return tokenFirebase;
};

