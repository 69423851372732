import ReactGA from 'react-ga';

export const GApageView = (page) => {
  ReactGA.pageview(page);
}

export const GAmodalView = (modal) => {
  ReactGA.modalview(modal);
};

export const GAevent = (categoryName, eventName, label = null, value = null) => {
  ReactGA.event({
    category: categoryName,  // Required
    action: eventName,       // Required
    label: label || null,
    value: value || null
  });
}