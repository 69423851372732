import React from 'react';

const AlertIcon = ({ className, style, color }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" fill={color}  width="33" height="29" viewBox="0 0 33 29" style={style}>

      <path d="M15.4782 9.0625H17.5122C17.707 9.0625 17.8616 9.22109 17.8559 9.41367L17.4262 20.5152C17.4205 20.6965 17.2658 20.8438 17.0825 20.8438H15.9079C15.7246 20.8438 15.5699 20.7021 15.5642 20.5152L15.1345 9.41367C15.1287 9.22109 15.2834 9.0625 15.4782 9.0625ZM16.4981 21.9766C15.61 21.9766 14.8938 22.6846 14.8938 23.5625C14.8938 24.4404 15.61 25.1484 16.4981 25.1484C17.3861 25.1484 18.1023 24.4404 18.1023 23.5625C18.1023 22.6846 17.3861 21.9766 16.4981 21.9766ZM32.6262 24.9219L18.8815 1.35938C17.8273 -0.453125 15.1746 -0.453125 14.1146 1.35938L0.369891 24.9219C-0.684312 26.7287 0.633442 29 2.75331 29H30.2485C32.3569 29 33.6862 26.7344 32.6262 24.9219ZM30.2485 27.1875H2.74758C2.04287 27.1875 1.6017 26.4342 1.9512 25.8281L15.7017 2.26562C16.0512 1.66523 16.9392 1.65957 17.2887 2.26562L31.0392 25.8281C31.3944 26.4285 30.9532 27.1875 30.2485 27.1875Z" fill={color} />
    </svg>
  )
}

export default AlertIcon;