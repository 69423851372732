import React from 'react';

const Camera = ({ className, style, color }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" fill={color}  width="13" height="12" viewBox="0 0 13 12" style={style}>

      <path d="M8.23418 0.857143C8.31797 0.857143 8.39414 0.913393 8.42461 0.996429L8.98574 2.57143H11.7812C12.0047 2.57143 12.1875 2.76429 12.1875 3V10.7143C12.1875 10.95 12.0047 11.1429 11.7812 11.1429H1.21875C0.995313 11.1429 0.8125 10.95 0.8125 10.7143V3C0.8125 2.76429 0.995313 2.57143 1.21875 2.57143H4.0168L4.52715 1.13571C4.58555 0.969643 4.73789 0.857143 4.90801 0.857143H8.23418ZM8.23418 0H4.90801C4.4002 0 3.9457 0.332143 3.76797 0.833036L3.45312 1.71429H1.21875C0.545898 1.71429 0 2.29018 0 3V10.7143C0 11.4241 0.545898 12 1.21875 12H11.7812C12.4541 12 13 11.4241 13 10.7143V3C13 2.29018 12.4541 1.71429 11.7812 1.71429H9.54688L9.18379 0.696429C9.03652 0.275893 8.6582 0 8.23418 0ZM6.5 10.0714C4.81914 10.0714 3.45312 8.63036 3.45312 6.85714C3.45312 5.08393 4.81914 3.64286 6.5 3.64286C8.18086 3.64286 9.54688 5.08393 9.54688 6.85714C9.54688 8.63036 8.18086 10.0714 6.5 10.0714ZM6.5 4.5C5.26855 4.5 4.26562 5.55804 4.26562 6.85714C4.26562 8.15625 5.26855 9.21429 6.5 9.21429C7.73145 9.21429 8.73438 8.15625 8.73438 6.85714C8.73438 5.55804 7.73145 4.5 6.5 4.5Z" fill={color} />
    </svg>
  )
}

export default Camera;