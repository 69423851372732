import React from 'react';

const Send = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="white" width="23" height="26" viewBox="0 0 23 26">

      <path d="M22.548 0.288015C22.3965 0.158533 22.2125 0.0760379 22.0173 0.0501442C21.822 0.0242505 21.6237 0.0560245 21.4453 0.141763L0.0412598 10.4874V12.4893L9.0314 16.1924L14.798 25.3283H16.7424L22.8861 1.37889C22.9359 1.18275 22.9306 0.976082 22.8707 0.782949C22.8109 0.589816 22.6989 0.418173 22.548 0.288015ZM15.5419 23.4189L10.5852 15.566L18.2126 6.96329L17.0058 5.82861L9.31872 14.4984L1.90632 11.4452L20.9779 2.22663L15.5419 23.4189Z" fill="#white"/>
    </svg>
  )
}

export default Send;