import React, { Component } from "react";
import styles from "../assets/css/BrowserError.module.css";
import { relative } from "upath";
import { Row, Col } from 'antd';
import { GApageView } from '../utils/analytics.js'


class BrowserError extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0, height: 0,
      isMobile: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount = () => {
    GApageView("browser-error");
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      isMobile: window.innerWidth < 600
    });

  }

  render = () => {
    return (
      <div className={styles.content}
      >
        <div className={styles.container}>



          <div className={styles.main_text}
            style={{
              fontSize: this.state.isMobile ? '20px' : '30px',
              lineHeight: this.state.isMobile ? '27px' : '40px',
              marginTop: this.state.isMobile ? '20px' : '35px',
              marginBottom: this.state.isMobile ? '20px' : '35px',
            }}
          >
            Tu navegador no es soportado o la versión que estás usando es muy antigua
          </div>

          <img
            src={require('../assets/images/error.png')}
            className={styles.main_img}
            alt=""
            height={this.state.isMobile ? 50 : 90}
          />
          <div className={styles.secondary_text}
            style={{
            fontSize: this.state.isMobile ? '16px' : '25px',
              lineHeight: this.state.isMobile ? '22px' : '30px',
              marginTop: this.state.isMobile ? '25px' : '30px',
              marginBottom: this.state.isMobile ? '20px' : '30px',
            }}>
            
              <p>Por favor usa uno de los navegadores soportados o actualiza tu navegador y nuevamente puedes dar clic al vínculo enviado.</p>
            
            {this.state.width > 900 && <br />}
            {!this.state.isMobile &&
              <p>¿Cuáles son los navegadores soportados?</p>
            }
          </div>

          <Row className={styles.browsers_section}>
            <Col className={styles.browser_item} flex="auto">
              <img
                src={require('../assets/images/desktop.png')}
                className={styles.browser_icon}
                alt=""
                height="40"
              />
              <p className={styles.browser_text}
                style={{ fontSize: this.state.isMobile ? '12px' : '20px', lineHeight: this.state.isMobile ? '16px' : '27px' }}
              >
                PC / Mac
              </p>

              <p className={styles.browser_text}
                style={{ fontSize: this.state.isMobile ? '10px' : '15px', lineHeight: this.state.isMobile ? '14px' : '20px' }}
              >
                Chrome | Firefox | Safari
              </p>
              <img
                src={require('../assets/images/Chrome.png')}
                className={styles.browser_icon}
                alt=""
                height={this.state.isMobile ? 20: 40}
              />
              <img
                src={require('../assets/images/Mozzila.png')}
                className={styles.browser_icon}
                alt=""
                height={this.state.isMobile ? 20 : 40}
                style={{ marginLeft: '15px', marginRight: '15px'}}
              />
              <img
                src={require('../assets/images/Safari.png')}
                className={styles.browser_icon}
                alt=""
                height={this.state.isMobile ? 20: 40}
              />
            </Col>
            <Col className={styles.browser_item} flex="auto">
              <img
                src={require('../assets/images/android.png')}
                className={styles.browser_icon}
                alt=""
                height="40"
              />
              <p className={styles.browser_text}
                style={{ fontSize: this.state.isMobile ? '12px' : '20px', lineHeight: this.state.isMobile ? '16px' : '27px' }}
              >
                Android
              </p>
              <p className={styles.browser_text}
                style={{ fontSize: this.state.isMobile ? '10px' : '15px', lineHeight: this.state.isMobile ? '14px' : '20px' }}
              >
                Chrome
              </p>
              <img
                src={require('../assets/images/Chrome.png')}
                className={styles.browser_icon}
                alt=""
                height={this.state.isMobile ? 20 : 40}
              />
            </Col>
            <Col className={styles.browser_item} flex="auto">
              <img
                src={require('../assets/images/iphone.png')}
                className={styles.browser_icon}
                alt=""
                height="40"
              />
              <p className={styles.browser_text}
                style={{ fontSize: this.state.isMobile ? '12px' : '20px', lineHeight: this.state.isMobile ? '16px' : '27px' }}
              >
                iPhone
              </p>
              <p className={styles.browser_text}
                style={{ fontSize: this.state.isMobile ? '10px' : '15px', lineHeight: this.state.isMobile ? '14px' : '20px' }}
              >
                Safari
              </p>
              <img
                src={require('../assets/images/Safari.png')}
                className={styles.browser_icon}
                alt=""
                height={this.state.isMobile ? 20 : 40}
              />
            </Col>
          </Row>


          


          {
            this.state.width <= 900 &&

            <div
              style={{ position: relative, marginTop: '60px' }}

            >
              <div className={styles.info_saludtools_content}>
                <p className={styles.info_saludtools_name}
                style={{textAlign:'center',lineHeight:'15px'}}>
                  Tecnología
                  </p>
                <div className={styles.info_saludtools_img}>
                  <img
                    src={require('../assets/images/logo.png')}
                    alt=""
                    height={this.state.isMobile ? 30 : 60}
                  />

                </div>
                
              </div>
            </div>

          }

        </div>

        {
          this.state.width > 900 &&

          <div className={styles.info_saludtools}
            style={{ width: (this.state.width - 700) / 2 }}

          >
            <div className={styles.info_saludtools_content}>
              <p className={styles.info_saludtools_name}>
                Tecnología
                  </p>
              <img
                src={require('../assets/images/logo.png')}
                className={styles.info_saludtools_img}
                alt=""
                width="200"
              />
            </div>
          </div>

        }
      </div>
    );
  }
}

export default BrowserError;