import React, { Component, PureComponent } from "react";
import styles from "../assets/css/MessageBubble.module.css";
import PropTypes from "prop-types";
import { Spin, Modal, Button,Row, Col } from "antd";
import Moment from 'react-moment';
import 'moment-timezone';
import Icon from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/es';

import numeral from 'numeral';

import DownloadFile from '../assets/icons/DownloadFile.js';

class MessageBubble extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasMedia: this.props.message.type === "media",
      mediaDownloadFailed: false,
      mediaUrl: null
    };
  }

  componentDidMount = async () => {
      moment.locale('es');
    this.setState({
      ...this.state,
      type: (await this.props.message.getParticipant()).type
    });
    if (this.state.hasMedia) {
      this.props.message.media
        .getContentTemporaryUrl()
        .then((url) => {
          this.setState({ mediaUrl: url });
        })
        .catch((e) => this.setState({ mediaDownloadFailed: true }));
    }
    document
      .getElementById(this.props.message.sid)
      .scrollIntoView({ behavior: "smooth" });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    document
      .getElementById(this.props.message.sid)
      .scrollIntoView({ behavior: "smooth" });
  }

  render = () => {
    const m = this.props.message;
    const isRight = !(this.props.direction === "incoming") ? styles.right : '';

    return (
      <li id={m.sid}>
        
        <div
    className={`${styles.conversation_list} ${isRight}`}
  >                                    
          <div>
            <div className={styles.ctext_wrap}>
              <div
                className={isRight ? styles.ctext_wrap_content_right : styles.ctext_wrap_content}
              >
          {!isRight &&
          <div className={styles.conversation_name} style={{color:this.props.color}}>{m.attributes.senderName}</div>
          }
      <div className={styles.medias}>
              {this.state.hasMedia && (
                <Media
                  hasFailed={this.state.mediaDownloadFailed}
                  url={this.state.mediaUrl}
                  type={m.media.state.contentType}
                  size={m.media.state.size}
                  fileName={m.attributes.fileName ? m.attributes.fileName : "Documento"}
                />
              )}
            </div>
                <p
                  className={styles.mb_0}
                >
          {m.body}
      </p>
                

              <p className={`${styles.chat_time} ${styles.mb_0}`}
                ><Moment fromNow parse="YYYY-MM-DD HH:mm">
                    {m.state.timestamp}
              </Moment></p>

                
            </div>
            
        </div>
        
    </div>
  </div></li>

    );
  };
}

class Media extends PureComponent {

  render = () => {
    const { hasFailed, url, type, size, fileName} = this.props;

    if (type.includes('image')) {
      return (
      <div
        className={`${styles.media}${!url ? " " + styles.placeholder : ""}`}
        onClick={() => {
          Modal.info({
            centered: true,
            icon: null,
            okText: "Cerrar",
            width: "60%",
            content: (
              <div className={styles.picture_container}>
                <img style={{ width: "100%", height: "100%" }} src={url} />
              </div>
            )
          });
        }}
      >
        {!url && !hasFailed && <Spin />}

        {hasFailed && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Icon type={"warning"} style={{ fontSize: "5em" }} />
            <p>Failed to load media</p>
          </div>
        )}

        {!hasFailed && url && (
          <div className={styles.picture_preview}>
            <img style={{ width: "100%", height: "100%" }} src={url} />
          </div>
        )}
      </div>
    );
    } else {
      return (
        <div
          style={{
          width:"100%"
        }}
        >
        {!url && !hasFailed && <Spin />}

        {hasFailed && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Icon type={"warning"} style={{ fontSize: "5em" }} />
            <p>Failed to load media</p>
          </div>
        )}

          {!hasFailed && url && (
            <div
            >
              <a style={{cursor: 'pointer'}} href={url} target="_blank" download title="Titulo de descarga" >
                <div>
                  <div className={styles.download_title}>
                    <div>
                      <DownloadFile className={styles.download_icon}/>
                    </div>
                    <div style={{maxWidth:'150px'}}>
                      <p className={styles.download_text}>{fileName}</p>
                      <p className={styles.download_text}>
                        {numeral(size).format('0.00 b')}
                      </p>

                    </div>

                  </div>


              </div>
              <Button
                style={{
                  width: "100%",
                  backgroundColor: "#5B7FB6",
                  borderRadius: '5px',
                  marginTop: '10px',
                  borderColor: "#5B7FB6",
                }}
              key="submit"
              type="primary"
              onClick={() => this.setState({ modal2Visible: false })}
            >
              Descargar
            </Button>

</a>
            </div>


            
        )}
      </div>
      )
    }
    
    
  };
}

Media.propTypes = {
  hasFailed: PropTypes.bool.isRequired,
  url: PropTypes.string
};

export default MessageBubble;
