export const colors = [
  '#0020C2',
  '#F52887',
  '#6960EC',
  '#4CC417',
  '#F75D59',
  '#7D0552',
  '#48CCCD',
  '#800517',
  '#FFA62F',
]