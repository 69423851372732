import { ENV } from '../api-config';

const HTTP_ACTION = "HTTP_ACTION";
const CONVERSATION = "CONVERSATION"
const TOKEN = "TOKEN"
const IMAGE = "IMAGE"

const URL = ENV.REST_BASE_URL;


export const httpMiddleware = store => next => action => {
   if (action[HTTP_ACTION]) {
     const actionInfo = action[HTTP_ACTION];
     const fetchOptions = {
        method: actionInfo.verb,
        headers: actionInfo.headers,
        body: actionInfo.payload || null
     };
     
     next({
        type: actionInfo.type + "_REQUESTED"
     });
     
     fetch(actionInfo.endpoint, fetchOptions)
    .then(response => response.json())
    .then(data => next({
        type: actionInfo.type + "_RECEIVED",
        payload: data
    }))
    .catch(error => next({
        type: actionInfo.type + "_FAILED",
        payload: error
     }));      
   } else {
     return next(action);
   }
}

function httpAction(action) {
   const httpActionTemplate = {
      type: "",
      endpoint: null,
      verb: "GET",
      payload: null,
      headers: []
   };

   return {
      HTTP_ACTION: Object.assign({}, httpActionTemplate, action)
   };
}

export function getTwilioPatientToken(token) {
  var data = new FormData()
  data.append('token', token)
   return httpAction({
      type: TOKEN,
      endpoint: `${URL}/patientConversation/patientToken`,
      verb: "POST",
      payload: data
   });
}

export function initConversation(token) {
  var data = new FormData()
  data.append('token', token)
   return httpAction({
      type: CONVERSATION,
      endpoint: `${URL}/patientConversation/initConversation`,
      verb: "POST",
      payload: data
   });
}

export function getCompanyImage(token) {
   return httpAction({
      type: IMAGE,
      endpoint: `${URL}/patientConversation/getCompanyImage?token=${token}`,
      verb: "GET",
   });
}