import React from 'react';

const Image = ({ className, style, color }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" fill={color}  width="25" height="20" viewBox="0 0 18 28" style={style}>

      <path d="M19.2656 5.46838L14.8958 0.950709C14.4271 0.466097 13.7917 0.191483 13.1302 0.191483H2.5C1.11979 0.196867 0 1.35455 0 2.78147V25.1814C0 26.6083 1.11979 27.766 2.5 27.766H17.5C18.8802 27.766 20 26.6083 20 25.1814V7.29914C20 6.61529 19.7344 5.95299 19.2656 5.46838ZM18.0885 6.69068C18.1979 6.80376 18.2708 6.93837 18.3073 7.08914H13.3333V1.94686C13.4792 1.98455 13.6094 2.05993 13.7187 2.17301L18.0885 6.69068ZM17.5 26.0429H2.5C2.04167 26.0429 1.66667 25.6552 1.66667 25.1814V2.78147C1.66667 2.30763 2.04167 1.91993 2.5 1.91993H11.6667V7.51991C11.6667 8.23606 12.224 8.81221 12.9167 8.81221H18.3333V25.1814C18.3333 25.6552 17.9583 26.0429 17.5 26.0429Z" fill={color} />
    </svg>
  )
}

export default Image;