import React, { Component } from "react";
import styles from "../assets/css/Splash.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { relative } from "upath";

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';


class Splash extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 0, height: 0,
      isMobile:false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }


  componentDidMount = () => {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  goChat = () => {
    this.props.goChat()
  }

  goBrowserError = () => {
    this.props.goBrowserError()
  }


  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      isMobile: window.innerWidth < 600
    });

  }

  render = () => {
    return (
      <div className={styles.content}>
        <div className={styles.container}>

          <img
            src={
              (this.props.conversation.imgCompany != '')
                ? this.props.conversation.imgCompany
                : require('../assets/images/logo.png')
            }
            className={styles.main_img}
            alt=""
            width={(this.props.conversation.imgCompany!='')  ? 100 : 200}
            onClick={this.goChat}
          />

          <h5 className={styles.main_text}>
          {
              this.state.isMobile ?
                "Estamos conectándote, un momento por favor" :
                "Estamos conectándote a un chat directo con tu médico / entidad, un momento por favor"

          }
            
            
            </h5>

          <FontAwesomeIcon icon={faSpinner} pulse color={'#2B73B6'} className={styles.icon} onClick={this.goBrowserError} />
          
          {
            this.state.width <= 900 &&

            <div
              style={{ position: relative, marginTop:'60px' }}

            >
              <div className={styles.info_saludtools_content}>
                <p className={styles.info_saludtools_name} style={{ textAlign: 'center', lineHeight: '15px' }}>
                  Tecnología
                  </p>
                <img
                  src={require('../assets/images/logo.png')}
                  className={styles.info_saludtools_img}
                  alt=""
                  width="200"
                />
              </div>
            </div>

          }
          
        </div>

        {
          this.state.width > 900 &&

          <div className={styles.info_saludtools}
            style={{ width: (this.state.width - 700) / 2 }}

          >
            <div className={styles.info_saludtools_content}>
              <p className={styles.info_saludtools_name}>
                Tecnología
                  </p>
              <img
                src={require('../assets/images/logo.png')}
                className={styles.info_saludtools_img}
                alt=""
                width="200"
              />
            </div>
          </div>

        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { conversation: state.conversation };
}

export default withTranslation()(connect(
  mapStateToProps,
)(Splash));
