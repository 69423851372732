import React from 'react';

const Image = ({ className, style, color }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" fill={color} width="25" height="20" viewBox="0 0 25 20" style={style}>

      <path d="M22.6562 0.297882H2.34375C1.04932 0.297882 0 1.38381 0 2.72341V17.2766C0 18.6162 1.04932 19.7021 2.34375 19.7021H22.6562C23.9507 19.7021 25 18.6162 25 17.2766V2.72341C25 1.38381 23.9507 0.297882 22.6562 0.297882ZM23.4375 17.2766C23.4375 17.7223 23.0869 18.0851 22.6562 18.0851H2.34375C1.91309 18.0851 1.5625 17.7223 1.5625 17.2766V2.72341C1.5625 2.27772 1.91309 1.9149 2.34375 1.9149H22.6562C23.0869 1.9149 23.4375 2.27772 23.4375 2.72341V17.2766ZM5.46875 8.78724C6.979 8.78724 8.20312 7.52041 8.20312 5.95746C8.20312 4.3945 6.979 3.12767 5.46875 3.12767C3.9585 3.12767 2.73438 4.3945 2.73438 5.95746C2.73438 7.52041 3.9585 8.78724 5.46875 8.78724ZM5.46875 4.74469C6.11475 4.74469 6.64062 5.28892 6.64062 5.95746C6.64062 6.62599 6.11475 7.17022 5.46875 7.17022C4.82275 7.17022 4.29688 6.62599 4.29688 5.95746C4.29688 5.28892 4.82275 4.74469 5.46875 4.74469ZM15.5776 5.90844L10.9375 10.7105L9.42236 9.14248C8.96484 8.669 8.22266 8.669 7.76514 9.14248L3.46826 13.5893C3.24854 13.8167 3.125 14.1249 3.125 14.4468V15.8617C3.125 16.1967 3.38721 16.4681 3.71094 16.4681H21.2891C21.6128 16.4681 21.875 16.1967 21.875 15.8617V11.2128C21.875 10.8914 21.7515 10.5826 21.5317 10.3552L17.2349 5.90844C16.7773 5.43496 16.0352 5.43496 15.5776 5.90844ZM20.3125 14.8511H4.6875V14.6141L8.59375 10.5715L10.9375 12.9971L16.4062 7.33748L20.3125 11.38V14.8511Z" fill={color} />
    </svg>
  )
}

export default Image;