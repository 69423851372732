import { ENV } from '../api-config';

var apigClientFactory = require('aws-api-gateway-client').default;
const config = {invokeUrl:ENV.GATEWAY_INVOKE_URL}
var apigClient = apigClientFactory.newClient(config);



export const sendNotifications = (participantTokens,message,conversationSid,senderName) => {

var method = 'POST';
var additionalParams = {
    headers:  { 'noLoader': true }
};
  

  var body = {
    tokens: participantTokens,
    message: message,
    conversationSid: conversationSid,
    senderName: senderName,
    senderSide: 'PATIENT'
  };


  apigClient.invokeApi({}, '', method, additionalParams , body)
    .then(function (result) {
    console.log(result)
    }).catch(function (result) {
      console.log(result)
    });
};