import MessageBubble from "./MessageBubble";
import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import styles from "../assets/css/MessageBubble.module.css";

class ConversationsMessages extends PureComponent {
  render = () => {
    const isRight = false ? styles.right : '';
    
    return (
        <div id="messages">
          <ul className={styles.list_unstyled}>
          {this.props.messages.map(m => {
              if (m.author === this.props.identity)
                return <MessageBubble key={m.index} direction="outgoing" message={m} />;
              else {
                return <MessageBubble key={m.index} direction="incoming" message={m} color={this.props.colors[this.props.participants.indexOf(m.state.author)]} />;
              }
                
            })
            }
          </ul>
          
        </div>
    );
  }
}

ConversationsMessages.propTypes = {
  identity: PropTypes.string.isRequired,
  messages: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ConversationsMessages;