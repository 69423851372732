import React, { Component } from 'react';
import '../assets/css/Conversation.css';
import Dropzone from 'react-dropzone';
import {Button, Form, Input, Modal } from "antd";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faFile, faImage } from '@fortawesome/free-regular-svg-icons'
import { faChevronLeft, faPaperclip, faCamera } from '@fortawesome/free-solid-svg-icons'
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from 'react-floating-button-menu';
import ConversationsMessages from "../components/ConversationsMessages";
import styles from '../assets/css/Conversation.module.css';
import { withTranslation } from 'react-i18next';
import { GApageView, GAevent } from '../utils/analytics.js';
import { connect } from 'react-redux'
import {
  getTwilioPatientToken,
  initConversation,
  getCompanyImage
} from "../actions/index.js";
import {awsUpdateItem,awsBatchItemsFCM} from '../aws/dynamo';
import { sendNotifications } from '../aws/gateway';
import { colors } from '../constants/colors';
import Send from '../assets/icons/Send.js';
import Image from '../assets/icons/Image.js';
import Attachment from '../assets/icons/Attachment.js';
import Document from '../assets/icons/Document.js';
import background from '../assets/images/logo-cuadrado.png';
import Camera from '../assets/icons/Camera.js';
import AlertIcon from '../assets/icons/AlertIcon.js';
import {isMobile, isIOS} from 'react-device-detect';



var shuffle = require('shuffle-array');
const auxMobile = isIOS ? 120 : 80;
const auxHeight = isMobile ? auxMobile : 30 ;
class Conversation extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      newMessage: '',
      conversationProxy: props.conversationProxy,
      messages: [],
      loadingState: 'initializing',
      boundConversations: new Set(),
      isOpen: false,
      width: 0, height: 0,
      isMobileSize: false,
      isLoading: false,
      browserError: false,
      typeFile: '',
      modal2Visible: false,
      companyName: JSON.parse(this.props.conversationProxy.channelState.friendlyName).companyName,
      lastIndex: 0,
      participantsId: [],
      colorsNames: [],
      heightAlert: '110px'
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  loadMessagesFor = (thisConversation) => {
    if (this.state.conversationProxy === thisConversation) {
      thisConversation.getMessages()
        .then(messagePaginator => {
          if (this.state.conversationProxy === thisConversation) {
            this.setState({ messages: messagePaginator.items, loadingState: 'ready' });
          }
        })
        .catch(err => {
          console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
          this.setState({ loadingState: "failed" });
        });
    }
  };

  componentDidMount = () => {


    setTimeout(() => {
      this.setState({
        heightAlert: (this.divAlert.clientHeight + auxHeight) + 'px',
      })   
    }, 1000);

    this.generateColors()
    GApageView('/');
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    if (this.state.conversationProxy) {
      this.loadMessagesFor(this.state.conversationProxy);

      if (!this.state.boundConversations.has(this.state.conversationProxy)) {
        let newConversation = this.state.conversationProxy;
        newConversation.on('messageAdded', m => this.messageAdded(m, newConversation));
        this.setState({ boundConversations: new Set([...this.state.boundConversations, newConversation]) });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      isMobileSize: window.innerWidth < 600,
      heightAlert: (this.divAlert.clientHeight + auxHeight ) + 'px'
    });
  }
  onChangeFile(event) {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];

    this.updateInfoAfterSendMsg(true,'',file)

    this.setState({file});
}

  componentDidUpdate = (oldProps, oldState) => {
    if (this.state.conversationProxy !== oldState.conversationProxy) {
      this.loadMessagesFor(this.state.conversationProxy);

      if (!this.state.boundConversations.has(this.state.conversationProxy)) {
        let newConversation = this.state.conversationProxy;
        newConversation.on('messageAdded', m => this.messageAdded(m, newConversation));
        this.setState({ boundConversations: new Set([...this.state.boundConversations, newConversation]) });
      }
    }
  };

  static getDerivedStateFromProps(newProps, oldState) {
    let logic = (oldState.loadingState === 'initializing') || oldState.conversationProxy !== newProps.conversationProxy;
    if (logic) {
      return { loadingState: 'loading messages', conversationProxy: newProps.conversationProxy };
    } else {
      return null;
    }
  }

  messageAdded = (message, targetConversation) => {
    if (targetConversation === this.state.conversationProxy)
      this.setState((prevState) => ({
        messages: [...prevState.messages, message]
      }));    
  };

  onMessageChanged = event => {
    this.setState({ newMessage: event.target.value });
    GAevent('mensaje', 'mensaje escrito');
  };


  getMessageAttributes = (isMedia=false, fileName='') => {
    let messageAttributes = {senderName: ''}

    let p = this.state.conversationProxy.participants._c;
    let myIdentity = this.props.myIdentity;
    let f = Array.from(p).filter(function (participant) {
      return participant[1].identity === myIdentity
    })[0]
    let nf = f[1].state.attributes.firstName + ' ' + f[1].state.attributes.firstLastName;
    
    if (f[1].state.identity.includes('patient')) {
      messageAttributes.senderName = nf;
    }

    if (isMedia) {
      messageAttributes.fileName = fileName;
    }

    return messageAttributes;
  }

  sendMessage = event => {
    event.preventDefault();
    const message = this.state.newMessage;
    let index = 0;
    this.setState({ newMessage: '' },
      () =>
      {
        this.updateInfoAfterSendMsg(false,message,null)
      }
    );
  };


  generateColors() {
    let participants = [];
    let keyColors = colors;
    shuffle(keyColors);

    for (let index = 0; index < this.props.conversation.data.length; index++) {
      participants.push(this.props.conversation.data[index].userId + '_user')
    }

    this.setState({
      participantsId: participants,
      colorsNames: keyColors
    })

  }

  updateInfoAfterSendMsg = async (isMedia=false,message='',file=null) => {

    let res;
    let sid = this.state.conversationProxy.sid;

    if (isMedia) {
      res = await this.state.conversationProxy.sendMessage(
        {
          contentType: file.type,
          media: file,
        },
        this.getMessageAttributes(true, file.name)
      );
    
    } else {
      res = await this.state.conversationProxy.sendMessage(message, this.getMessageAttributes());
      GAevent('mensaje', 'mensaje enviado');
    }

    
    if (res < this.state.lastIndex) {
      return
    }

    this.setState({ lastIndex: res }, () => {
      let participants = this.props.conversation.data;
      let participantsId = [];
      let senderName = this.getMessageAttributes().senderName;

      if (participants !== []) {
        participants.map(p => {
          awsUpdateItem(p.id, p.userId.toString(), res.toString());
          if (p.userId !== p.patientId) {
              participantsId.push(p.userId + '_user');
          }
        })
      }
      

      if (participantsId.length <= 0) {
        return
      }
      
    var getTokens = awsBatchItemsFCM(participantsId);
    getTokens.then(function(result) {
      
      if (result.length > 0) {
        sendNotifications(
          result,
          isMedia ? file.name.substring(0,30) : message.substring(0,30),
          sid,
          senderName
        )
      }
    });


      
    })
  };



  pickAttachment = (type) => {
    let aceptados = '';

    if (type == 'file') {
      aceptados = ".doc,.docx,application/msword,.pdf"
    } else if  (type == 'imagen') {
      aceptados = "image/*"
    } 

    this.setState({ typeFile: aceptados ,isOpen: !this.state.isOpen}, () => {
      this.upload.click()
    })
    
  };


  onDrop = acceptedFiles => {
    if (acceptedFiles[0] == null) {
      this.setState({ modal2Visible: true })
      return
    } 
    this.updateInfoAfterSendMsg(true,'',acceptedFiles[0])
  };

  goChat = () => {
    this.setState({ isLoading: false })
  }

  goBrowserError = () => {
    this.setState({ browserError: true, isLoading: false })
  }


  handleChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      const reader = new FileReader();
      reader.onload = function(){
        console.log( reader);
      };
      
    }
  };

  render = () => {
    
    const { t } = this.props;
    const inputMobile = this.state.isMobileSize ? styles.input_mobile : '';
    return (

      <Dropzone
        onDrop={this.onDrop}
        accept=".doc,.docx,application/msword,.pdf,image/*"
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            {...getRootProps()}
            onClick={() => {
            }}
            id="OpenChannel"
            style={{ position: "relative", top: 0, backgroundColor: '#FDFDFD', height: '100%' }}
            className={styles.container}
          >

            {false && (
              <div className={styles.drop}>
                <h3 style={{ color: "#fefefe" }}>Release to Upload</h3>
              </div>
            )}
          <div className={styles.topContainer}>
            <div
              className={styles.header}
              style={{
                height: this.state.isMobileSize ? '60px' : '80px',
                paddingTop: this.state.isMobileSize ? '10px' : '10px',
                paddingBottom: this.state.isMobileSize ? '10px' : '10px',

              }}
            >
              <div className={styles.header_content}>
                {
                  this.state.isMobileSize &&
                  <div style={{ marginRight: 20, marginTop: 5 }}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </div>
                }

                <div className="mr-3">

                  <div
                    className={styles.avatar}
                    style={{
                      backgroundImage:
                      (this.props.conversation.imgCompany != '')
                        ? `url(${this.props.conversation.imgCompany})`
                        : `url(${background})` ,
                    }}
                  ></div>
                </div>
                <div className={styles.header_name}
                  style={{
                    fontSize: this.state.isMobileSize ? '15px' : '20px',

                  }}
                >
                 {this.state.companyName}
                  </div>
              </div>
            </div>

            
            
            <div
                className={styles.alertInformation}
                ref={ (divAlert) => { this.divAlert = divAlert } }
                style={{
                  position: 'absolute',
                  top: this.state.isMobileSize ? '80px' : '100px',
                  width: this.state.isMobileSize ? '90%' : '70%',
                  display: 'flex',
                  flexDirection: 'row',
                  fontSize: this.state.isMobileSize ? '12px' : '14px',
                  padding: this.state.isMobileSize ? '15px' : '20px',
                }}

              >
                
                
                <div
                  style={{
                    height: this.state.isMobileSize ? '40px' : '50px',
                    width: this.state.isMobileSize ? '40px' : '50px',
                    display: 'flex',
                    marginBottom: 'auto',
                    marginTop: 'auto',
                    marginRight: '10px',


                }}  
                ><AlertIcon className={styles.alertIcon} color={"#856404"}/>
                    </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex'
                }} 
                  >{t('Alerta informacion')}</div>
                
              </div>
            
            
            </div>
        <Modal
          title="Error con tipo de archivo"
          centered
          visible={this.state.modal2Visible}
          footer={[
            <Button
              key="submit"
              type="primary"
              onClick={() => this.setState({ modal2Visible: false })}
            >
              Ok
            </Button>,
          ]}
        >
          <p>Sólo puede enviar imágenes, vídeos o documentos del tipo doc o pdf</p>
        </Modal>
            <div
              className={styles.messages}
              style={{
                filter: `blur(${isDragActive ? 4 : 0}px)`,
                height: '100%'
              }}
            >
              
              <input id="files" {...getInputProps()} />
              <div
                style={{

                  height: "100%",
                  overflowY: "hidden",
                  paddingTop: this.state.heightAlert,
                  paddingBottom: '70px',
                }}
              >
                <ConversationsMessages
                  identity={this.props.myIdentity}
                  messages={this.state.messages}
                  participants={this.state.participantsId}
                  colors={this.state.colorsNames}
                />
              </div>
            </div>

            <div className={styles.footer}>
              <input
                id="myInput"
                type="file"
                accept={this.state.typeFile}
                ref={(ref) => this.upload = ref}
                style={{display: 'none'}}
                onChange={this.onChangeFile.bind(this)}
              />



              <Form
                autoComplete="off" 
                autoCorrect="off"
                spellCheck="off"
                style={{
                  paddingBottom: this.state.isMobileSize && '10px',
                  backgroundColor: 'rgb(240, 240, 240)'
                }}
              >
                
                <Input.Group
                  compact
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: '#f0f0f0',
                    border: '1px solid #f0f0f0',
                    paddingLeft: this.state.isMobileSize ? 20 : 50,
                  }}
                >
                  <input
                    autoComplete="false"
                    name="hidden"
                    type="text"
                    style={{display: 'none'}}
                  />

                  <input
                    autoComplete="none"
                    autoCorrect="off"
                    spellCheck="off"
                    type="text"
                    style={{
                      width: "100%",
                      margin: 10,
                      marginRight: '10px',
                      fontSize: this.state.isMobileSize && '12px',
                      height: '40px',

                    }}
                    className={`${styles.input_chat} ${inputMobile}`}
                    placeholder={t('Ingresa un mensaje')}
                    onChange={this.onMessageChanged}
                    value={this.state.newMessage}
                  />

                  {
                    this.state.isMobileSize &&
                    <div className={styles.icons_mobile} >
                      <Button
                        style={{
                          display: 'flex',
                          background: 'none',
                          width: 'auto',
                          height: 'auto',
                      position: 'relative',
                          border:'none',
                      left: '-35px',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                          padding:0
                      }}
                        onClick={() => this.pickAttachment('file')}>
                        <Attachment className={styles.attachment_icon_mobile}
                          color='#666666'
                        style={{
                          fontSize: '15px',
                          position: 'relative',
                          height: '15px'
                        }}/>
                      </Button>
                      <Button
                        style={{
                          display: 'flex',
                          background: 'none',
                          width: 'auto',
                          height: 'auto',
                          position: 'relative',
                          left: '-25px',
                          marginTop: 'auto',
                          marginBottom: 'auto',
                      padding: 0,
                          border:'none',
                      }}
                        onClick={() => this.pickAttachment('imagen')}>
                        <Camera className={styles.attachment_icon_mobile}
                          color='#666666'
                        style={{
                                                    fontSize: '20px',
                          position: 'relative',
                          height: '18px'
                        }}/>
                        </Button>
                    </div>
                  }

                  <Button
                    style={{
                      verticalAlign: 'center',
                      marginRight: '20px'
                    }}
                    htmlType="submit"
                    className={styles.btn_send}
                    type="submit"
                    onClick={this.sendMessage}
                    disabled={this.state.newMessage===''}
                  >
                    <Send className={styles.send_icon} />
                  </Button>
                </Input.Group>
              </Form>

            </div>


            {!this.state.isMobileSize &&
                <FloatingMenu
                  slideSpeed={500}
                  direction="up"
                  spacing={8}
                  isOpen={this.state.isOpen}
                  className={styles.btn_group}
                >
                  
                  <MainButton
                  iconResting={
                    <Attachment className={styles.attachment_icon_main} color='#666666' />}
                    iconActive={<Attachment className={styles.attachment_icon_main} color='#666666' />}
                    background={this.state.isOpen ? "#C4C4C4" : "#f0f0f0"}
                    className={styles.attachment_icon_main}
                    onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                    size={40}
                  />
                <ChildButton
                  icon={<Image className={styles.attachment_icon} color='white'/>}
                    background="#5B7FB6"
                    style={{ color: 'white' }}
                    size={40}
                    onClick={() => this.pickAttachment('imagen')}
                  />
                 
                <ChildButton
                  icon={<Document className={styles.attachment_icon} color='white' />}
                    background="#5B7FB6"
                    style={{ color: 'white' }}
                    size={40}
                    onClick={() => this.pickAttachment('file')}
                  />

                </FloatingMenu>
              }

            {
              this.state.width > 1050 &&

              <div className={styles.info_saludtools}
                style={{ width: (this.state.width - 700) / 2 }}

              >
                <div className={styles.info_saludtools_content}>
                  <p className={styles.info_saludtools_name}>
                    Tecnología
                  </p>
                  <img
                    src={require('../assets/images/logo.png')}
                    className={styles.info_saludtools_img}
                    alt=""
                    width="200"
                  />
                </div>
              </div>

            }
          </div>
        )}

      </Dropzone>
    );
  }
}
const mapStateToProps = (state) => {
  return { conversation: state.conversation };
}

function mapDispatchToProps(dispatch) {
  return {
    getToken: (token) => dispatch(getTwilioPatientToken(token)),
    getConversation: (token) => dispatch(initConversation(token)),
    getCompanyImg: (token) => dispatch(getCompanyImage(token)),
  };
}


Conversation.propTypes = {
  myIdentity: PropTypes.string.isRequired
};

export default withTranslation()(connect(
  mapStateToProps,
  mapDispatchToProps
)(Conversation));
