import React from 'react';

const DownloadFile = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="none" width="33" height="33" viewBox="0 0 33 33">

      <path d="M19.7919 0L29.6952 9.9L29.6969 16.929C28.73 16.1265 27.6062 15.5347 26.3974 15.1915L26.3958 11.55H18.1471V3.3H3.29947V29.7H11.6471C11.8359 30.8893 12.2972 32.0187 12.995 33H1.63819C1.23716 33 0.850078 32.8528 0.55028 32.5864C0.250483 32.32 0.0587988 31.9529 0.0115481 31.5546L0 31.3632V1.6368C0 0.8151 0.638447 0.10725 1.46002 0.0115499L1.65303 0H19.7919ZM23.9212 18.15C25.4525 18.15 26.9212 18.7584 28.004 19.8415C29.0869 20.9245 29.6952 22.3934 29.6952 23.925L29.6936 24.095C30.7256 24.3858 31.6214 25.0328 32.2218 25.9213C32.8223 26.8098 33.0888 27.8823 32.9739 28.9485C32.859 30.0148 32.3703 31.0059 31.5943 31.746C30.8184 32.4861 29.8054 32.9275 28.7351 32.9918L28.4579 33H19.3844L19.1072 32.9918C18.0371 32.9269 17.0246 32.485 16.2491 31.7447C15.4737 31.0043 14.9854 30.0131 14.871 28.947C14.7565 27.8809 15.0232 26.8087 15.6238 25.9205C16.2244 25.0324 17.1201 24.3856 18.152 24.095L18.1471 23.925C18.1471 22.3934 18.7554 20.9245 19.8383 19.8415C20.9211 18.7584 22.3898 18.15 23.9212 18.15ZM23.9212 21.45C23.3463 21.4499 22.7894 21.6499 22.346 22.0157C21.9026 22.3815 21.6003 22.8903 21.4911 23.4548L21.4581 23.6857L21.4466 23.925V26.598L19.0643 27.2679C18.7898 27.3412 18.5489 27.5067 18.382 27.7368C18.2151 27.9668 18.1325 28.2472 18.148 28.531C18.1635 28.8147 18.2761 29.0845 18.467 29.295C18.6579 29.5055 18.9154 29.6438 19.1963 29.6868L19.3761 29.7H28.4662C28.7653 29.6977 29.0535 29.5872 29.2774 29.3887C29.5013 29.1903 29.6457 28.9175 29.6839 28.6207C29.7222 28.324 29.6516 28.0235 29.4854 27.7747C29.3191 27.526 29.0684 27.346 28.7796 27.2679L27.1084 26.8009L26.3958 26.598V23.925L26.3842 23.6874C26.3252 23.075 26.0401 22.5065 25.5848 22.0929C25.1294 21.6792 24.5363 21.45 23.9212 21.45Z" fill="#666666"/>
    </svg>
  )
}

export default DownloadFile;